

.article {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 10px;
  padding: 10px;

}

.main {
  flex-grow: 1;  padding: 5px;

}

.header,
.main,
.footer {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: -0.5em;
  margin-left: -0.5em;
}

.btn {
  margin-bottom: 0.5em;
  margin-left: 0.5em;
}

.app-content {
  padding: 15px;

}

.react-calendar {
  width: 300px;
}